import { Component } from "react"
import React from "react"

class Footer extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (<footer className="page-footer font-small blue pt-4">
            <div className="container-fluid text-center text-md-left">
                <div className="row">
                    <div className="col-md-6 mt-md-0 mt-3">
                        <h5 className="text-uppercase">A Must-Feed production</h5>
                        <p>Hmm... Not sure what am I looking at?</p>
                        See <a href="https://must-feed.com/2023/04/20/prompt-engineering/">the post</a> I wrote on how I did it!
                    </div>

                    <hr className="clearfix w-100 d-md-none pb-0" />

                    <div className="col-md-6 mb-md-0 mb-3">
                        <h5 className="text-uppercase">Techs that were used</h5>
                        <ul className="list-unstyled">
                            <li><a href="https://developers.google.com/maps/documentation/places/web-service">Google Places</a></li>
                            <li><a href="https://openai.com">ChatGPT</a></li>
                            <li><a href="https://react-bootstrap.github.io/">React Bootstrap</a></li>
                            <li><a href="https://react.dev/">React JS</a></li>
                            <li><a href="https://nodejs.org/en">Node JS</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="footer-copyright text-center py-3">© 2024 "Copyright":
                <a href="https://must-feed.com/">Must-Feed</a>
            </div>

        </footer>)
    }
}

export default Footer
