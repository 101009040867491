import logo from './takis.png';
import React from "react";
import MyMapWithAutocomplete from './MyMapWithAutocomplete.js';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Navbar from 'react-bootstrap/Navbar';
import Footer from './Footer';
import './App.css'
import './index.css'

function App() {
  return (
    <>
      <Container className="p-3 container-md">
        <Navbar bg="dark" variant="dark">
          <Navbar.Brand href="/">
            <img
              alt="phony fry"
              src={logo}
              width="30"
              height="30"
              className="d-inline-block align-top"
            />{' MyOpinion '}

          </Navbar.Brand>
          <span className="navbar-text">
            (Takis Opinionated Restaurant Review ChatGPT-3 Google Places Mashup)
          </span>
        </Navbar>
        <Row>
          <Col className='col-md-12'>
            <h1 className="header text-center">Huh? What do you mean?</h1>
            What if we got all the google restaurant reviews, mash them together and feed that to ChatGPT? That would be a good idea 
            but, what if that result was also coming from someone who wrote this app too? Would you still visit that restaurant on that guy's opinion? I don't know... It's up for you to decide.
          </Col>
        </Row>
        <Row>
          <Col className='col-md-12'>
            When I'll have more time not working on K8s, I will update (actually rewrite) this app to be more helpful, by calling multiple google api places and fetching a list of the higher rating / reviews, which I think is the most accurate one.
          </Col>
        </Row>
        <hr></hr>
        <Row>
          <Col className='col-md-12'>
            <h3 className="header text-center">How does it work?</h3>
            <ul>
              <li>Type the first letters from your favorite restaurant in the search box in the map below.</li>
              <li>Tap or click at the autocompleted selection.</li>
              <li>Scroll down past the 5 most relevant reviews.</li>
              <li>Click the "Ask Takis" button!</li>
            </ul>
            For now, only the restaurants in Greece are being displayed.
          </Col>
        </Row>
        <MyMapWithAutocomplete></MyMapWithAutocomplete>
      </Container>
      <Footer></Footer>
    </>
  );
}

export default App;