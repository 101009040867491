import { Component } from 'react'
import { GoogleMap, LoadScript, Autocomplete, InfoWindow } from '@react-google-maps/api'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import axios from 'axios'
import Modal from 'react-bootstrap/Modal'
import logo from './takis.png'
import Spinner from 'react-bootstrap/Spinner';
import './App.css'


const mapContainerStyle = {
  height: "40em",
}

let center = {
  lat: 38.9998871,
  lng: 22.9873479
}

let zoom = 7.5

const backendURL = process.env.REACT_APP_BACKEND_URL

const saveToCookie = (cookieName, cookieValue) => {
  document.cookie = `${cookieName}=${JSON.stringify(cookieValue)}`
}

const readCookie = (cookieName) => {
  const cookies = document.cookie.split(';')
  let formCookie = ""
  cookies.forEach((cookie) => {
    if (cookie.includes(`${cookieName}=`)) {
      formCookie = cookie.replace(`${cookieName}=`, "")
    }
  })
  return formCookie
}

class MyMapWithAutocomplete extends Component {
  constructor(props) {
    super(props)
    this.autocomplete = null

    this.onLoadAP = this.onLoadAP.bind(this)
    this.onLoadIW = this.onLoadIW.bind(this)
    this.onPlaceChanged = this.onPlaceChanged.bind(this)
    this.setMap = this.setMap.bind(this)
    this.setPlace = this.setPlace.bind(this)
    this.state = { place: null, gtpresponse: null, modalShow: false, takisThinking: false }

  }

  setMap(map) {
    this.map = map
  }

  setPlace(place) {

    this.setState({ 'place': place })
  }

  onLoadAP(autocomplete) {

    this.autocomplete = autocomplete
  }

  onPlaceChanged() {

    if (this.autocomplete !== null) {
      console.log('place changed')

      const place = this.autocomplete.getPlace()

      if (place != null && !place.hasOwnProperty('place_id')) {
        alert('Dude, please select the restaurant, you typed: ' + place.name + ' instead! ')
        this.setPlace(null)
        return
      }
      this.setPlace(place)

      this.map.panTo({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() })
      this.map.setZoom(17)

    } else {
      console.log('Autocomplete is not loaded yet!')
    }
  }

  askTakis() {
    if (this.state.place == null) {
      this.setState({ gtpresponse: <Alert variant='danger'>Hey, select a place from the searchbox above man!</Alert> })
    } else {
      this.postData(this.state.place)
    }
    this.setState({ modalShow: true })
    this.setState({ place: null })
  }

  onLoadIW(infoWindow) {
    console.log('infoWindow: ', infoWindow)
  }

  recordCalls() {
    let numeberOfCalls = readCookie("number_of_calls") != '' ? parseInt(readCookie("number_of_calls")) : 0
    numeberOfCalls++
    saveToCookie("number_of_calls", numeberOfCalls)
  }

  validateCalls() {
    const numeberOfCalls = parseInt(readCookie("number_of_calls"))
    if (numeberOfCalls > 5) {
      this.setState({ gtpresponse: <Alert variant='danger'>I'm sorry, but I cannnot allow you to send any more requests! OpenAI is charging me by the request and it is costing an arm and a leg...</Alert> })
      return false
    }
    return true
  }

  async postData(place) {
    const data = {
      name: place.name,
      id: place.place_id,
      rating: place.rating.toString() + "/5",
      totalReviews: place.user_ratings_total,
      type: place.types.join(','),
      vicinity: place.vicinity,
      comments: place.reviews
    }

    try {
      if (this.validateCalls()) {
        this.setState({ takisThinking: true })
        const resp = await axios.post(`${backendURL}/opinion`, data, { auth: { username: 'takis', password: '-4k1$||y0?1nIon4t3D' } })
        this.setState({ 'gtpresponse': <p>{resp.data.ok}</p> })
        this.recordCalls()
        this.setState({ takisThinking: false })
      }

    } catch (e) {
      this.setState({ takisThinking: false })
      console.log(e)
      this.setState({ 'gtpresponse': <Alert variant='danger'>I'm sorry but there was an error, or you are trying way too many requests!</Alert> })
    }

  }

  render() {
    let comments = ''
    if (this.state.place !== null) {
      comments =
        <>
          <hr></hr>
          <h2>Reviews</h2>
          <Row xs={1} md={2} className="g-4">
            {this.state.place.reviews.map((item, key) =>
              <Col key={key}>
                <Card>
                  <Card.Img variant="top" src={item.profile_photo_url} className='cardThumb align-self-center' />
                  <Card.Header>{item.author_name}</Card.Header>
                  <Card.Title className='align-self-center'>Rating: {item.rating}/5</Card.Title>
                  <Card.Body>{item.text}</Card.Body>
                  <Card.Footer>
                    <small className="text-muted">{item.relative_time_description}</small>
                  </Card.Footer>
                </Card>
              </Col>
            )}
          </Row>
        </>
    }


    const divStyle = {
      background: `white`,
      // border: `1px solid #ccc`,
      overflow: 'hidden',
      padding: 10
    }

    const infoWindow =
      this.state.place !== null ?
        <InfoWindow
          onLoad={this.onLoadIW}
          position={{ lat: this.state.place.geometry.location.lat(), lng: this.state.place.geometry.location.lng() }}
        >
          <div style={divStyle}>
            <h4>{this.state.place.name}</h4>
            <div>
              {/* <img href={this.state.place.icon} className=''></img> */}
              {/* <br></br> */}
              Google Address: {this.state.place.adr_address.replace(/(<([^>]+)>)/gi, "")}
              <br></br>
              Google Website: <a href={this.state.place.website}>Website</a>
              <br></br>
              Google Rating: {this.state.place.rating}/5
              <br></br>
              Google Total User Ratings: {this.state.place.user_ratings_total}
              <br></br>
              Google Main Type: {this.state.place.types[0]}
            </div>
          </div>
        </InfoWindow>
        : ''

    return (
      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        libraries={["places"]}
      >
        <Row>
          <Col className='col-md-12'>
            <GoogleMap
              id="searchbox-example"
              mapContainerStyle={mapContainerStyle}
              zoom={zoom}
              center={center}
              onLoad={this.setMap}
              className="z-depth-1-half map-container"
            >
              <Autocomplete
                onLoad={this.onLoadAP}
                onPlaceChanged={this.onPlaceChanged}
                types={["restaurant"]}
                options={{ language: 'en' }}
                restrictions={{ country: ['gr'] }}
              >
                <input
                  type="text"
                  placeholder="Search restaurant"

                  style={{
                    boxSizing: `border-box`,
                    border: `1px solid transparent`,
                    width: `400px`,
                    height: `36px`,
                    padding: `0 12px`,
                    borderRadius: `3px`,
                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                    fontSize: `14px`,
                    outline: `none`,
                    textOverflow: `ellipses`,
                    position: "relative",
                    top: "70px",
                    display: 'block',
                    margin: '0 auto'
                  }}
                />

              </Autocomplete>
              {infoWindow}
            </GoogleMap>
          </Col>
        </Row>
        {comments}
        <hr></hr>
        <Row>
          <Col className='d-grid gap-2 col-6 mx-auto'>
            <Button variant="primary" size="lg" onClick={() => this.askTakis(true)}>
              Ask Takis
            </Button>
          </Col>
        </Row>
        <Modal
          show={this.state.modalShow}
          onHide={() => this.setState({ modalShow: false })}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              You asked!
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col className='col-md-4'>
                <img src={logo} className='img-fluid mx-auto d-block rounded-circle' ></img>
              </Col>
              <Col className='col-md-8'>
                <h4>My Opinion</h4>

                {this.state.takisThinking ? <Spinner className="text-center" animation="border" variant="primary" /> : this.state.gtpresponse}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Col className='d-grid gap-2 d-md-flex justify-content-md-end'>
              <Button onClick={() => this.setState({ modalShow: false })} variant="secondary">Eh, didn't find what I was looking for</Button>
              <Button href="https://www.buymeacoffee.com/sfitsos" variant="primary">OMG this helped me, I want to buy you a beer!!!</Button>
            </Col>
          </Modal.Footer>
        </Modal>
      </LoadScript>
    )
  }
}
export default MyMapWithAutocomplete;